import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { SHOP_SERVICE_TYPE } from 'global_constants';
import { getGooglePlaceValue, isEmpty } from '_dash';

dayjs.extend(utc);
dayjs.extend(customParseFormat);

export const getDifferenceInHours = (startTime) => {
  const bookingStartTime = dayjs.utc(startTime);
  const currentDateTime = dayjs.utc();
  return currentDateTime.diff(bookingStartTime, 'hour') * -1;
};

export const getBookingStart = (booking) => {
  return dayjs(booking.start).utc(false).format('hh:mm A (dddd, DD MMMM)');
};

export const getBookingServices = (booking) => {
  return (booking.booked_services || []).map((item) => item.name).join(', ');
};

export const getBookingNote = (barber, booking) => {
  return `${barber.display_name}: “${booking.barber_note}”`;
};

export const getBookingAddress = (booking) => {
  return booking.location_type === SHOP_SERVICE_TYPE
    ? booking.barber?.shop_address?.formatted_name || 'shop'
    : booking?.booking_address?.address_text || 'N/A';
};

export const getScheduleItem = (schedule) => {
  return `${dayjs(schedule.from_time, 'hh:mm:ss').format('h:mm A')} - ${dayjs(
    schedule.to_time,
    'hh:mm:ss'
  ).format('h:mm A')}`;
};

export const getMapLink = (address) => {
  return `https://www.google.com/maps/?q=${address}`;
};

export const openMapLink = (address) => {
  return window.open(getMapLink(address), '_blank');
};

export const setMetaDescription = (content) => {
  const meta = document.getElementsByTagName('meta');
  for (var i = 0; i < meta.length; i++) {
    if (meta[i].name.toLowerCase() === 'description') {
      meta[i].content = content;
    }
  }
};

export const getCalloutDisplayAddress = (place) => {
  let displayName = '';
  if (!isEmpty(place)) {
    const postalCode = getGooglePlaceValue(place, ['postal_code']);
    const city = getGooglePlaceValue(place, ['locality', 'postal_town']);
    displayName = [postalCode, city].filter((i) => i).join(', ');
  }
  return displayName;
};
